import { dbUser } from "./database";
import { getParams, isValidEmail } from "./utils";
import { signedIn, loadedApi, openShareFile, rePermission } from "./store";
import { get } from "svelte/store";
let access_token;
const init = async () => {
	await gapi.load("client:auth2", async function () {
		gapi.auth2.init({ client_id: process.env.CLIENT_ID });

		const currentUser = await gapi.auth2.getAuthInstance().currentUser;
		currentUser.listen(gUser => {
			const basicProfile = gUser?.getBasicProfile();
			if (basicProfile) {
				const email = basicProfile.getEmail();
				signedIn.set(true);
				dbUser.update({ email });
			} else {
				signedIn.set(false);
				dbUser.update({ email: getParams("email") });
			}
		});
	});
};
let pickerApiLoaded = false;

init();

export const getCurrentUserEmail = async () => {
	const currentUser = await gapi.auth2.getAuthInstance().currentUser.get();
	const email = currentUser.getBasicProfile().getEmail();
	return Promise.resolve(email);
};

export const handleDocs = async newFileTitle => {
	const response = await gapi.client.docs.documents.create({
		resource: {
			title: newFileTitle,
		},
	});
	return Promise.resolve({
		fileId: response.result.documentId,
		fileUrl: "https://docs.google.com/document/d/" + response.result.documentId + "/edit",
	});
};

export const handleSheets = async newFileTitle => {
	const response = await gapi.client.sheets.spreadsheets.create({
		resource: {
			properties: {
				title: newFileTitle,
			},
		},
	});

	return Promise.resolve({
		fileId: response.result.spreadsheetId,
		fileUrl: "https://docs.google.com/spreadsheets/d/" + response.result.spreadsheetId + "/edit",
	});
};

export const handleSlides = async newFileTitle => {
	const response = await gapi.client.slides.presentations.create({
		resource: {
			title: newFileTitle,
		},
	});
	return Promise.resolve({
		fileId: response.result.presentationId,
		fileUrl: "https://docs.google.com/presentation/d/" + response.result.presentationId + "/edit",
	});
};

export async function authenticate() {
	return gapi.auth2
		.getAuthInstance()
		.signIn({
			scope: "https://www.googleapis.com/auth/drive",
		})
		.then(
			async function (guser) {
				console.log("Sign-in successful");
				const { access_token: at } = await guser.getAuthResponse(true);
				access_token = at;
				return Promise.resolve(true);
			},
			function (err) {
				console.error("Error signing in", err);
			}
		);
}
export const loadClient = async loadedApi => {
	if (loadedApi === true) {
		return Promise.resolve(true);
	}
	try {
		const loadApis = await Promise.all([
			gapi.client.setApiKey(process.env.API_KEY),
			gapi.client.load("https://docs.googleapis.com/$discovery/rest?version=v1"),
			gapi.client.load("https://sheets.googleapis.com/$discovery/rest?version=v4"),
			gapi.client.load("https://slides.googleapis.com/$discovery/rest?version=v1"),
			gapi.client.load("https://content.googleapis.com/discovery/v1/apis/drive/v3/rest"),
		]);
		loadedApi = true;
		console.log("GAPI client loaded for API");
		return Promise.resolve(loadedApi);
	} catch (err) {
		console.error("Error loading GAPI client for API", err);
	}
};

async function loadApi() {
	const result = await loadClient(get(loadedApi));
	loadedApi.set(result);
}

export const addPermissions = async (fileId, users) => {
	await loadApi();
	const batch = gapi.client.newBatch();

	for (let userId in users) {
		const { email } = users[userId];
		if (!isValidEmail(email)) continue;
		const request = gapi.client.drive.permissions.create({
			fileId,
			resource: {
				role: "writer",
				type: "user",
				emailAddress: email,
			},
		});
		batch.add(request);
	}
	return batch;
};
export const addPermission = async (fileId, email) => {
	await loadApi();
	await gapi.client.drive.permissions.create({
		fileId,
		resource: {
			role: "writer",
			type: "user",
			emailAddress: email,
		},
	});
	return getPermissions(fileId);
};
export const removePermission = async (fileId, permissionId) => {
	await loadApi();
	await gapi.client.drive.permissions.delete({
		fileId,
		permissionId,
	});
	return getPermissions(fileId);
};
// export const authenticateAndLoad = async isApiLoaded => {

// 	// const loaded = await loadClient(isApiLoaded);
// 	// return [signIn, loaded];
// };

const isScopePresent = async (scope) => {
	const user = await gapi.auth2.getAuthInstance().currentUser.get();
	const scopes = user.getGrantedScopes().split(" ");
	return scopes.includes(scope);
}

export const openFilePicker = async cb => {
	const isDriveScopePresent = await isScopePresent("https://www.googleapis.com/auth/drive");
	if (isDriveScopePresent) gapi.load("picker", { callback: () => onPickerApiLoad(cb) });
	else rePermission.set(true);
};
function onPickerApiLoad(callback) {
	pickerApiLoaded = true;
	createPicker(callback);
}

const getOAuthToken = async () => {
	if (!access_token) {
		const currentUser = await gapi.auth2.getAuthInstance().currentUser.get();
		const { access_token: at } = await currentUser.getAuthResponse(true);
		access_token = at;
	}
	return access_token;
};

async function createPicker(callback) {
	const oauthToken = await getOAuthToken();
	if (pickerApiLoaded && oauthToken) {
		var view = new google.picker.View(google.picker.ViewId.DOCS);
		var docViewMode = new google.picker.DocsView(view.getId());
		docViewMode.setMode(google.picker.DocsViewMode.LIST);
		var origin = window.location.ancestorOrigins?.length
			? window.location.ancestorOrigins[window.location.ancestorOrigins.length - 1]
			: null;

		view.setMimeTypes(
			"application/vnd.google-apps.document,application/vnd.google-apps.presentation,application/vnd.google-apps.spreadsheet"
		);
		var pickerObj = new google.picker.PickerBuilder()
			.setOAuthToken(oauthToken)
			.setDeveloperKey(process.env.API_KEY)
			.addView(docViewMode)
			.addView(new google.picker.DocsUploadView())
			.setCallback(data => pickerCallback(data, callback));
		if (origin) {
			pickerObj.setOrigin(origin);
		}
		var picker = pickerObj.build();
		picker.setVisible(true);
	}
}

async function pickerCallback(data, callback) {
	if (data.action == google.picker.Action.PICKED) {
		await callback(data.docs[0]);
	}
}

export const openShare = fileId => {
	openShareFile(fileId);
};

export async function getPermissions(fileId) {
	const {
		result: { permissions },
	} = await gapi.client.drive.permissions.list({
		fileId: fileId,
		fields: "*",
	});
	return permissions;
}

export const authenticateIfNot = async (isSignedIn, isApiLoaded) => {
	if (!isSignedIn) {
		const signIn = await authenticate();
		if (signIn) {
			const currentUserEmail = await getCurrentUserEmail();
			dbUser.update({ email: currentUserEmail });
			signedIn.set(signIn);
		} else {
			signedIn.set(false);
			throw new Error("Error while logging in!");
		}
	}
	if (!isApiLoaded) {
		const isLoaded = await loadClient(isApiLoaded);
		if (isLoaded) {
			loadedApi.set(isLoaded);
		} else {
			loadedApi.set(false);
			throw new Error("Error while loading api!");
		}
	}
};
